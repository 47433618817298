export default {
	name: 'Indonesia',
	created () {
		this.$nextTick(function () { })
	},
	mounted () {
		this.$nextTick(function () { })
	},
	data () {
		return {}
	},
	computed: {},
	watch: {},
	methods: {},
	components: {}
}
